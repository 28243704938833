"use client";

import {
  AuthenticatedRoutesWrapper,
  DappProvider,
} from "@multiversx/sdk-dapp/wrappers";
import { ThemeProvider } from "next-themes";
import { PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useRouter } from "next/navigation";

// const DEEP_LINK = 'https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://xportal.com/'
const PROJECT_ID = "339e7eb7e88522d86fdcd01f399cf83a";

const queryClient = new QueryClient();
const routes = [
  {
    path: "/dashboard",
    authenticatedRoute: true,
  },
  {
    path: "/collections/*",
    authenticatedRoute: true,
  },
  {
    path: "/dashboard/mint",
    authenticatedRoute: true,
  },
  {
    path: "/dashboard/kyc",
    authenticatedRoute: true,
  },
  {
    path: "/dashboard/edit-information",
    authenticatedRoute: true,
  },
  {
    path: "/dashboard/referral",
    authenticatedRoute: true,
  },
];

export default function GlobalProviders({ children }: PropsWithChildren) {
  const router = useRouter();

  return (
    <ThemeProvider forcedTheme="dark" attribute="class">
      <QueryClientProvider client={queryClient}>
        <DappProvider
          environment="mainnet"
          customNetworkConfig={{
            walletConnectV2ProjectId: PROJECT_ID,
            name: "Custom name",
          }}
        >
          <AuthenticatedRoutesWrapper
            onRedirect={(route) => {
              if (!route) {
                router.push("/login");
              } else router.push(route);
            }}
            routes={[
              ...routes,
              {
                path: "/signin",
                authenticatedRoute: true,
              },
            ]}
            unlockRoute={"/login"}
          >
            {children}
          </AuthenticatedRoutesWrapper>
        </DappProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
