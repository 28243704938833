"use client";

import React from "react";
import toast, { ToastBar, Toaster } from "react-hot-toast";

export function CustomToaster() {
  return (
    <Toaster position="top-right" containerClassName="overflow-hidden">
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <div className="flex flex-row items-center">
              <div className="h-6 w-6">{icon}</div>
              <p className="text-md">{message}</p>
              {t.type !== "loading" && (
                <button onClick={() => toast.dismiss(t.id)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M18 6 6 18" />
                    <path d="m6 6 12 12" />
                  </svg>
                </button>
              )}
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}
